var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f9a5ff5314475565e3b3dcb7c045791c9579604a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Note: The DSN is not a secret and is supposed to be exposed client side. This is not an accidental
// leake to include this in our source control. This file was auto generated by Sentry.
Sentry.init({
  dsn: SENTRY_DSN || 'https://dd939499e5cb4be5a00f92b6afa88d36@o532705.ingest.sentry.io/5651915',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  // 20% of all transaction requests are sent to Sentry. Random selection. Error events are not affected.
  // transaction requests are used for performance monitoring
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllText: false,
      networkDetailAllowUrls: [window.location.origin, /^https:\/\/union\.gettactic\.com\/api\//]
    })
  ]
});
