import type { MantineThemeOverride } from '@mantine/styles/lib/theme/types';

const theme: MantineThemeOverride = {
  components: {
    Tabs: {
      styles: (theme) => ({
        tabsList: {
          borderBottom: '1px solid #EDF2F7'
        },
        tab: {
          paddingLeft: 0,
          paddingRight: 0,
          marginRight: '24px',
          color: 'var(--color-tertiary)',
          '&[data-active]': {
            color: 'var(--color-primary)',
            borderColor: 'var(--color-primary)'
          },
          '&[data-active]&:hover': {
            color: 'var(--color-primary)',
            borderColor: 'var(--color-primary)'
          },
          '&:hover': {
            color: 'var(--color-primary)',
            borderColor: 'var(--color-primary)',
            backgroundColor: 'transparent'
          }
        },
        tabLabel: {
          fontSize: '16px',
          '&[data-active]': {
            color: 'var(--color-primary)'
          },
          '&:hover': {
            color: 'var(--color-primary)',
            borderColor: 'var(--color-primary)',
            backgroundColor: 'transparent'
          }
        },
        panel: {
          paddingTop: '8px'
        }
      })
    },
    Modal: {
      styles: (theme) => ({
        header: { borderRadius: theme.spacing.xs },
        inner: {
          '> section': {
            overflowY: 'visible'
          },
          '.mantine-ScrollArea-root': {
            overflow: 'visible'
          },
          '.mantine-ScrollArea-viewport': {
            overflow: 'visible !important'
          }
        }
      })
    },
    Stepper: {
      styles: (theme) => ({
        stepWrapper: {
          borderColor: `var(--color-primary)`
        },
        stepCompleted: {
          backgroundColor: `var(--color-primary)`,
          color: theme.white,
        },
        stepIcon: {
          fontWeight: 'bold',
          '&[data-progress]': {
            borderColor: `var(--color-primary)`,
            backgroundColor: `var(--color-primary-lighter)`,
          },
          '&[data-completed]': {
            backgroundColor: `var(--color-primary)`,
            borderColor: `var(--color-primary)`,
          }
        },
        separatorActive: {
          backgroundColor: `var(--color-primary)`,
        }
      })
    }
  }
};

export default theme;
